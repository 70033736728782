@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* Default font for LTR content */
body {
  font-family: "Poppins", sans-serif;
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
}

/* Arabic font for RTL content */
[dir="rtl"] body,
[dir="rtl"] *:not(i):not(.fa):not(.fas):not(.far):not(.fab) {
  font-family: "Cairo", sans-serif !important;
  line-height: 1.8 !important;
  letter-spacing: -0.01em !important;
}

/* Fixed font sizes */
html {
  font-size: 16px;
}

@media (max-width: 640px) {
  html {
    font-size: 14px;
  }
}

/* RTL Support */
[dir="rtl"] .ml-3 {
  margin-left: 0;
  margin-right: 0.75rem;
}
[dir="rtl"] .mr-2 {
  margin-right: 0;
  margin-left: 0.5rem;
}
[dir="rtl"] .right-6 {
  right: auto;
  left: 1.5rem;
}
[dir="rtl"] .right-0 {
  right: auto;
  left: 0;
}
[dir="rtl"] .right-3 {
  right: auto;
  left: 0.75rem;
}
[dir="rtl"] .text-left {
  text-align: right;
}
[dir="rtl"] .border-l-4 {
  border-left: none;
  border-right: 4px solid;
}

[dir="rtl"] p {
  line-height: 1.8 !important;
  margin-bottom: 0.5em !important;
}

/* Animations */
@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

@keyframes float-delayed {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
}

.animate-float {
  animation: float 6s ease-in-out infinite;
}

.animate-float-delayed {
  animation: float-delayed 8s ease-in-out infinite 1s;
}

@keyframes shine {
  0% {
    background-position: 200% center;
  }
  100% {
    background-position: -200% center;
  }
}

.animate-shine {
  animation: shine 8s linear infinite;
  background-size: 200% auto;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fadeInUp {
  animation: fadeInUp 1s ease-out forwards;
}

/* Glass effect */
.glass-effect {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

/* Custom scrollbar */
.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: rgba(var(--accent-color), 0.3) transparent;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(var(--accent-color), 0.3);
  border-radius: 3px;
}

/* Dark theme scrollbar */
.scrollbar-dark::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.05);
}

.scrollbar-dark::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.1);
}

.scrollbar-dark::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

/* Light theme scrollbar */
.scrollbar-light::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.05);
}

.scrollbar-light::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
}

.scrollbar-light::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

/* Firefox scrollbar colors */
.scrollbar-dark {
  scrollbar-color: rgba(255, 255, 255, 0.1) rgba(255, 255, 255, 0.05);
}

.scrollbar-light {
  scrollbar-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.05);
}

/* Nav item animations */
.nav-item {
  position: relative;
  transition: all 0.3s ease;
}

.nav-item::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;
  background: linear-gradient(
    to right,
    var(--primary-color),
    var(--accent-color)
  );
  transition: width 0.3s ease;
}

.nav-item:hover::after {
  width: 100%;
}

/* Enhanced Button Animations */
.shine-button::after {
  content: "";
  position: absolute;
  top: -50%;
  left: -100%;
  width: 50%;
  height: 200%;
  background: linear-gradient(
    to right,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  transform: rotate(25deg);
  transition: 0.6s;
}

.shine-button:hover::after {
  animation: shine 1s ease-in-out;
}

/* Gradient text */
.gradient-text {
  background: linear-gradient(
    to right,
    var(--primary-color),
    var(--accent-color)
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

/* Social icon animations */
.social-icon {
  position: relative;
  overflow: hidden;
  isolation: isolate;
}

.social-icon::before {
  content: "";
  position: absolute;
  inset: 0;
  background: linear-gradient(45deg, var(--primary-color), var(--accent-color));
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: -1;
  border-radius: 0.75rem;
}

.social-icon:hover::before {
  opacity: 1;
}

.social-icon svg {
  transform: scale(1);
  transition: transform 0.3s ease;
}

.social-icon:hover svg {
  transform: scale(1.2);
}

/* Background grid */
.bg-grid-white {
  background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.05) 1px,
      transparent 1px
    ),
    linear-gradient(to bottom, rgba(255, 255, 255, 0.05) 1px, transparent 1px);
  background-size: 24px 24px;
}

/* Enhanced hover states */
.hover-scale {
  transition: transform 0.3s ease;
}

.hover-scale:hover {
  transform: scale(1.05);
}

/* Particle animation */
@keyframes particle-float {
  0% {
    transform: translate(0, 0) scale(0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(var(--tx), var(--ty)) scale(1);
    opacity: 0;
  }
}

.particle-star {
  animation: particle-float 3s infinite;
  opacity: 0;
}

/* Scroll-triggered animations */
.reveal {
  opacity: 0;
  transform: translateY(30px);
  transition: all 0.5s ease;
}

.reveal.active {
  opacity: 1;
  transform: translateY(0);
}

/* Card Base Styles */
.card-base {
  @apply rounded-2xl transition-all duration-300;
}

/* Light Theme Cards */
.card-light {
  @apply bg-white/80 backdrop-blur-sm border border-white/60
shadow-[0_10px_20px_rgba(0,0,0,0.06),_0_2px_6px_rgba(0,0,0,0.04),_0_0_1px_rgba(0,0,0,0.04)]
hover:shadow-[0_16px_30px_rgba(0,0,0,0.08),_0_4px_8px_rgba(0,0,0,0.06),_0_0_1px_rgba(0,0,0,0.04)]
hover:bg-white/90;
}

/* Dark Theme Cards */
.card-dark {
  @apply bg-sidebar-bg/30 border border-white/10
hover:bg-sidebar-hover/30;
}

/* Social Icon Cards */
.card-social {
  @apply p-2.5 rounded-xl transition-all duration-300 hover:scale-105;
}

.card-social-light {
  @apply bg-white/80 backdrop-blur-sm hover:bg-white/90 border border-white/60
shadow-[0_2px_10px_rgba(0,0,0,0.06)] hover:shadow-[0_5px_15px_rgba(0,0,0,0.08)];
}

.card-social-dark {
  @apply bg-sidebar-bg/30 hover:bg-sidebar-hover/30;
}

/* Contact Info Cards */
.card-contact {
  @apply flex items-start gap-4 p-4 rounded-2xl transition-all duration-300
hover:translate-y-[-2px];
}

/* Icon Container */
.icon-container {
  @apply relative p-2.5 rounded-xl transition-all duration-300 overflow-hidden;
}

.icon-container-light {
  @apply bg-gradient-to-br from-accent-light/10 to-accent-color/5;
}

.icon-container-dark {
  @apply bg-accent-color/10;
}

@keyframes float-particle {
  0% {
    transform: translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(var(--tx, -50px), var(--ty, -50px));
    opacity: 0;
  }
}

@keyframes gradient-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes ping-slow {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  75%,
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

.animate-gradient-rotate {
  animation: gradient-rotate 8s linear infinite;
}

.animate-ping-slow {
  animation: ping-slow 2s cubic-bezier(0, 0, 0.2, 1) infinite;
}

.quick-action-btn {
  @apply w-12 h-12 flex items-center justify-center rounded-xl
backdrop-blur-sm transition-all duration-300
shadow-lg hover:shadow-xl;
}

.tooltip-text {
  @apply absolute -top-10 left-1/2 -translate-x-1/2 px-3 py-1.5
bg-black/80 text-white text-sm rounded-lg
opacity-0 group-hover:opacity-100
transition-opacity duration-300 pointer-events-none
whitespace-nowrap;
}

.nav-button {
  @apply p-3 rounded-full bg-black/50 text-white
hover:bg-black/70 transition-all duration-300
backdrop-blur-sm border border-white/10
transform hover:scale-110;
}

@keyframes border-flow {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.animate-border-flow {
  background-size: 300% 300%;
  animation: border-flow 3s ease infinite;
}

@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

@keyframes border-rotate {
  to {
    --angle: 360deg;
  }
}

.animate-border-rotate {
  background: linear-gradient(
    var(--angle),
    #4fd1c5 0%,
    #38b2ac 50%,
    #4fd1c5 100%
  );
  animation: border-rotate 3s linear infinite;
}

/* Group hover styles */
.group:hover .bg-gray-800\/50,
.group:hover .bg-accent-color\/10,
.group:hover .bg-accent-light\/10 {
  background-color: transparent;
}

.group:hover .via-accent-color {
  --tw-gradient-via-position: 50%;
}

@layer utilities {
  .perspective-[1000px] {
    perspective: 1000px;
  }

  .preserve-3d {
    transform-style: preserve-3d;
  }

  .transform-gpu {
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
  }

  @keyframes shine {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }

  .animate-shine {
    animation: shine 8s linear infinite;
  }
}

:root {
  --mouse-x: 50%;
  --mouse-y: 50%;
}

.transform-gpu {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
}

.animate__animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animate-element {
  animation-duration: 1s;
}

@media (max-width: 767px) {
  .hero-title {
    animation-duration: 0.5s !important;
  }
}
